.intro-detail {
    position: relative;
    width: 100%;
    padding-left: 50px; //delete later
    padding-right: 50px; //delete later
    padding-top: 20px;

    //background-color: blue;

    @media (min-width: 500px) {
    }

    @media (min-width: 768px) {
    }

    @media (min-width: 1024px) {
        display: flex;
        flex-direction: row-reverse;
    }

    @media (min-width: 1200px) {
    }

    &__pagenumber-wrapper {
        //background-color: pink;
        margin: 0 auto;
        padding-right: 40px;
        display: flex;
        justify-content: center;
        padding-left: 25px;

        @media (min-width: 768px) {
            position: absolute;
            top: 47%;
            width: 100px;
            transform: rotate(-90deg);
            left: 0.5%;
        }
    }

    &__left {
        display: flex;
        width: 100%;
        padding-left: 20px;
        padding-top: 20px;
    }

    &__svg-wrapper {
        width: 50px;
        height: 50px;
        position: absolute;
        left: 20px;

        @media (min-width: 768px) {
            width: 70px;
            height: 70px;
        }

        @media (min-width: 1024px) {
            width: 80px;
            height: 80px;
        }
    }

    &__title--mobile, &__title--desktop {
        font-family: 'Lora', sans-serif;
        font-weight: 700;
        color: #33338E;
        letter-spacing: 3px;
    }

    &__title--mobile {

        display: block;
        font-size: 45px;
        text-align: center;
        margin-top: 25px;
        margin-bottom: 60px;

        @media (min-width: 768px) {
            margin-bottom: 100px;
        }

        @media (min-width: 1024px) {
            display: none;
        }
    }

    &__title--desktop {
        display: none;

        @media (min-width: 1024px) {
            display: block;
            font-size: 45px;
            margin-top: 25px;
            margin-bottom: 40px;
            display: block;
        }
    }

    &__img-wrapper {
        width: 100%;
        //background-color: green;
        //background-color: purple;

        margin-bottom: 60px;

        @media (min-width: 500px) {

        }

        @media (min-width: 768px) {

        }

        @media (min-width: 1024px) {
            width: 50%;
            position: relative;
            margin-bottom: 0;
        }
    }

    &__text {
        text-align: center;
        font-family: 'Lora', sans-serif;
        color: #33338E;
        letter-spacing: 2px;
        font-size: 13px;
        line-height: 2;
        margin-bottom: 40px;

        @media (min-width: 768px) {
           font-size: 15px;
        }

        @media (min-width: 1024px) {
            text-align: left;
        }
    }

    &__info {
        font-family: 'Lora', sans-serif;
        color: #33338E;
        letter-spacing: 2px;
        font-size: 13px;
        margin-bottom: 40px;
        line-height: 2;

        @media (min-width: 768px) {
            font-size: 15px;
         }
    }

    &__text-block {
        //background-color: yellow;

        text-align: center;
        font-family: 'Lora', sans-serif;
        color: #33338E;
        display: flex;
        justify-content: center;

        @media (min-width: 768px) {
            margin-bottom: 50px;
        }

        @media (min-width: 1024px) {
            width: 50%;
            text-align: left;
            //background-color: pink;
            justify-content: flex-start;
            padding-left: 60px;
            padding-right: 60px;
            padding-top: 120px;
            // padding-bottom: 60px;
            margin: 0;
        }
    }

    &__text-wrapper {
          max-width: 350px;
          //background-color: green;

      @media (min-width: 768px) {
          max-width: 400px;
      }

      @media (min-width: 1200px) {
          max-width: 650px;
      }
    }

    &__link {
        text-align: center;
    }

   a {
          color:#8989e8;
          font-weight: 300;
    }

    &__wave {
    display: none;

      @media (min-width: 768px) {
          display: block;
          margin-top: -70px;
          margin-bottom: 30px;
      }

      @media (min-width: 1024px) {
          background-image: url("../images/wave--desktop.png");
          height: 40px;
          background-size: cover;
          background-position: center;
          margin-top: 5px;
          margin-bottom: 50px;
      }

      @media (min-width: 1024px) {
          background-image: url("../images/wave--desktop.png");
          height: 40px;
          background-size: contain;
          margin-top: 5px;
          margin-bottom: 50px;
      }

      @media (min-width: 1200px) {
        background-repeat: no-repeat;
        background-size: contain;
        height: 70px;
        margin-top: -20px;
        margin-bottom: 80px;
      }


    }
}
