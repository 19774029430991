.project {
    @media (max-width: 500px) {
        padding-top: 50px;
    }

    @media (min-width: 1024px) {
        padding-top: 50px;
        padding-bottom: 50px;
        padding-left: 100px;
    }

    &__pagenumber {
        &--wrapper {
          display: flex;
          justify-content: center;
          width: 100%;
          margin-top: 15px;
          height: 50px;

          @media (min-width: 768px) {
              position: absolute;
              top: 165px;
              transform: rotate(-90deg);
              left: -50px;
              //background-color: green;
              width: 200px;
          }

          @media (min-width: 1024px) {
              top: 290px;
          }

        }
    }

    &__title {
        font-family: 'Lora', sans-serif;
        color: #33338E;
        letter-spacing: 3px;
        font-weight: 700;
        font-size: 30px;
        margin-bottom: 20px;


            text-align: center;
            margin-top: 20px;

            @media (min-width: 768px) {
                font-size: 50px;
                margin-top: 95px;
            }

            @media (min-width: 1024px) {
                display: none;
            }

    }

    &__title--desktop {

        display: none;

        @media (min-width: 1024px) {

          display: block;
          margin: 0;
          margin-bottom: 20px;
          font-family: 'Lora', sans-serif;
          color: #33338E;
          letter-spacing: 3px;
          font-weight: 700;
          font-size: 30px;
        //   width: 355px;

          //position: absolute;
          //top: 27%;
          //right: -5%;
          //transform: translate(-110%, -50%);
        }

        @media (min-width: 1200px) {

            font-size: 35px;

            //position: absolute;
            //top: 27%;
            //right: -5%;
            //transform: translate(-110%, -50%);
          }
    }

    &__text {
      font-family: 'Lora', sans-serif;
      color: #33338E;
      letter-spacing: 2px;
      font-size: 13px;
      line-height: 2;
      text-align: center;
      margin: 0 auto;
      margin-bottom: 60px;
      margin-top: 30px;
      padding-left: 50px;
      padding-right: 50px;

            @media (min-width: 500px) {
                margin-top: 20px;
            }

            @media (min-width: 768px) {
                // width: 500px;
                font-size: 15px;
                margin-bottom: 90px;
            }

            @media (min-width: 1024px) {
                //position: absolute;
                //top: 51%;
                //right: -6%;
                //transform: translate(-50%, -50%);
                // width: 400px;
                text-align: left;
                margin: 0;
                margin-bottom: 40px;
                padding:0;
            }

            @media (min-width: 1200px) {
                font-size: 17px;
            }
    }

    &__text-wrapper {
        @media (min-width: 1024px) {
            // display: block;
            // width: 500px;
            max-width: 700px;
        }
    }

    &__right-block {
        @media (min-width: 1024px) {
            // background-color: yellow;
            // height: 700px;
            display: flex;
            // justify-content: center;
            width: 100%;
            position: relative;
            // height: 700px;
            padding-top: 140px;
            padding-bottom: 50px;
        }

        @media (min-width: 1200px) {
           padding-top: 100px;
        }
    }

    &__button-wrapper {
        width: 143px;
        height: 50px;
        margin: 0 auto;
        position: relative;

        @media (min-width: 1024px) {
            margin: 0;
            margin-left: -19px;
        }
    }

    &__button-text, &__button-text:hover {
        position: absolute;
        top: 42%;
        left: 62%;
        transform: translate(-50%, -50%);
        width: 100px;
        font-family: 'Lora', sans-serif;
        font-size: 12px;
        letter-spacing: 2px;
        color: #fff;
        text-decoration: none;
    }

    &__button-text-centered {
        left: 80% !important;
    }

    &__label {
        color: white;
        padding: 10px;
        background-color: #6d6199;
        border-radius: 15px;
        font-family: 'Lora', sans-serif;
        letter-spacing: 2px;
        font-size: 12px;

        @media (max-width: 531px) {
            // max-width: 120px;
            margin: auto;
            margin-bottom: 10px;
         }

        @media (min-width: 531px) {
            margin-right: 5px;
         }

    }

    &__label-wrapper {
        display: flex;
        justify-content: center;
        margin-bottom: 50px;

        @media (max-width: 531px) {
            flex-direction: column;
         }

        @media (min-width: 728px) {
           margin-top: 0;
        }

        @media (min-width: 1024px) {
            justify-content: flex-start;
            margin-top: 0;
            margin-bottom: 50px;
        }

        @media (min-width: 1200px) {
            margin-bottom: 50px;
        }
    }

    // &__label-text {
    //     color: white;
    //     font-size: 15px;
    //     line-height: 2;
    //     text-align: center;
    //     margin: 0 auto;
    //     margin-bottom: 60px;
    //     margin-top: 30px;
    // }
}
