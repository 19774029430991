.blownaway-soundcloud, .blownaway-soundcloud2 {
  width: 100%;
//   position: absolute;
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 20px;
//   top: 1650px;

  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 40px;


  @media (min-width: 500px) {

  }

  @media (min-width: 692px) {

  }


  @media (min-width: 768px) {

  }

  @media (min-width: 768px) {

  }


  @media (min-width: 1024px) {
    margin-bottom: 80px;
  }

  @media (min-width: 1200px) {

  }

  &__wrapper2 {
    // top: 2000px;
  }

  &__pagenumber-wrapper {
      width: 100%;
      //background-color: crimson;
      display: flex;
      justify-content: center;
      margin-bottom: 25px;

      @media (min-width: 768px) {
          position: absolute;
          top: 60%;
          transform: rotate(-90deg);
          left: -46%;
      }
  }

  &__title-wrapper {
      width: 100%;
      //background-color: green;
      margin-bottom: 50px;
      display: flex;
      justify-content: center;
  }

  &__title {
      font-family: 'Lora', sans-serif;
      font-weight: 700;
      font-size: 30px;
      color: #33338E;
      letter-spacing: 3px;
      text-align: center;


      @media (min-width: 768px) {
        font-size: 45px;
    }
  }

  &__bins-wrapper {
      width: 100%;
      height: auto;
      //background-color: yellow;

      @media (min-width: 768px) {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
      }

      @media (min-width: 1200px) {
          justify-content: center;
          margin-bottom: 60px;
          padding-right: 60px;
          padding-left: 60px;
      }
  }

  &__bin {
      width: 100%;
      margin-bottom: 100px;
      //background-color: grey;

      @media (min-width: 768px) {
          width: 350px;
          margin: 0 auto;
          margin-bottom: 150px;
      }

      @media (min-width: 1024px) {
          width: 400px;
          //background-color:grey;
      }

      @media (min-width: 1200px) {
          width: 25%;
          position: relative;

      }
  }

  &__bin-title {
      color: #362F7F;
      text-align: center;
      font-family: 'Poppins', sans-serif;
      letter-spacing: 3px;
      font-size: 15px;
      margin-bottom: 50px;
      font-weight: 700;

      @media (min-width: 768px) {
          font-size: 20px;
          //background-color: brown;
          margin-bottom: 70px;
      }

      @media (min-width: 1200px) {
          font-size: 15px;
          //background-color: brown;


      }
  }

  &__bin1-img, &__bin2-img, &__bin3-img, &__bin4-img {
      background-size: 230px 270px;
      background-repeat: no-repeat;
      width: 230px;
      height: 270px;
      //background-color: blue;
      margin: 0 auto;

      @media (min-width: 768px) {
          background-size: 330px 370px;
          background-repeat: no-repeat;
          width: 330px;
          height: 370px;
      }

      @media (min-width: 1200px) {
          background-size: 240px 280px;
          background-repeat: no-repeat;
          width: 240px;
          height: 280px;
      }

  }

  &__bin1-img {
      background-image: url("../images/phase--1.png");

      @media (min-width: 768px) {

      }
  }

  &__bin2-img {
      background-image: url("../images/phase--2.png");
  }

  &__bin3-img {
      background-image: url("../images/phase--3.png");
  }

  &__bin4-img {
      background-image: url("../images/phase--4.png");
  }

  &__text-wrapper {
      // display: block;
      // width: 100%;
      // //background-color: yellow;

      // display: flex;
      // justify-content: center;

      // @media (min-width: 768px) {
      //     display: flex;
      //     justify-content: center;
      // }

      // @media (min-width: 1200px) {
      //     display: none;
      // }
  }

  &__text {
      color: #362F7F;
      font-family: 'Lora', sans-serif;
      letter-spacing: 3px;
      font-size: 13px;
      line-height: 2;
      text-align: center;
    //   width: 350px;
      margin: auto;
    //   top: 400px;

      @media (min-width: 500px) {
        //top: 1480px;
        // top: 400px;
    }

      @media (min-width: 768px) {
          width: 500px;
          //background-color: yellow;
          font-size: 15px;
      }

      @media (min-width: 1024px) {
        width: 600px;
        //background-color: yellow;
    }



  }

  &__text--desktop {

      display: none;

      @media (min-width: 1200px) {
          width: 40%;
          //background-color: crimson;
          display: block;
          position: absolute;
          // top: 135%;
          // left: 200%;
          // transform: translate(-50%, -50%);
          width: 600px;
          color: #362F7F;
          font-family: 'Lora', sans-serif;
          letter-spacing: 3px;
          font-size: 15px;
          line-height: 2;
          text-align: center;
      }

  }

  &__playlist {
    display: flex;
    justify-content: center;
    flex-direction: column;

    @media (min-width: 768px) {
        padding-right: 60px;
        padding-left: 60px;
            position: relative;
            width: 100%;
            overflow: hidden;
    }

  }

  &__iframe {
    margin: auto;
    margin-bottom: 50px;

    // @media (max-width: 768px) {
    //     width: 100%;
    //     height: 100%;
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     bottom: 0;
    //     right: 0;
    //     width: 100%;
    //     height: 100%;
    //     border: none;
    // }

    @media (max-width: 768px) {
        width: 100%;
    }

    @media (min-width: 768px) {
      margin: auto;
      margin-bottom: 80px;
  }
  }
}

.blownaway-soundcloud {

  @media (min-width: 500px) {

  }

@media (min-width: 692px) {

}


@media (min-width: 768px) {

}

@media (min-width: 768px) {

}


@media (min-width: 1024px) {

}

@media (min-width: 1200px) {

}
}

.blownaway-soundcloud2 {


  @media (min-width: 500px) {

}

@media (min-width: 692px) {

}


@media (min-width: 768px) {

}

@media (min-width: 768px) {

}


@media (min-width: 1024px) {

}

@media (min-width: 1200px) {

}
}
