.home {
    color: #fff;
    height: 100%;
    position: relative;

    &__menu {
        position: absolute;
        top: 0;
        right: 0;
        font-family: 'Poppins', sans-serif;
        letter-spacing: 2px;
    }

    &__menu--item {
        list-style-type: none;
        text-align: center;
        padding-right: 40px;
    }

    &__menu--link, &__menu--link:hover {
        text-decoration: none;
        // color: #fff;
        color: #fff;
    }

    &__menu--list {
        display: flex;
        top: 50%;
        left: 50%;
        margin-top: 15px;

        @media (min-width: 768px) {
            left: auto;
            right: 10px;
            transform: none;
            margin-top: 10px;
        }

        @media (min-width: 1024px) {
            right: 15px;
        }
    }

    &__info {

        //background-color: green;
        flex-direction: column;
        letter-spacing: 2px;
        font-family: 'Lora', sans-serif;
        text-align: center;

        @media (min-width: 1024px) {
            flex-direction: row;
        }
    }

    &__info--name, &__info--lastname {
        z-index: 99;
        font-size: 55px;
        font-weight: 700;
        letter-spacing: 5px;

        @media (min-width: 768px) {
            font-size: 80px;
        }

        @media (min-width: 1200px) {
            font-size: 90px;
        }

    }

    &__info--name {
        margin-left: -30px;
    }

    &__info--name-wrapper {

    }

    &__info--lastname {
        margin-left: 30px;

        @media (min-width: 500px) {
            margin-left: 100px;
        }

        @media (min-width: 1024px) {
            margin-left: 200px;
        }
    }

    &__info--spec {
        //background-color: pink;
        z-index: 99;
    }

    &__info--wrapper {
        position: absolute;
        top: 50%;  /* position the top  edge of the element at the middle of the parent */
        left: 50%; /* position the left edge of the element at the middle of the parent */

        transform: translate(-50%, -50%);

        @media (min-width: 500px) {
            left: 45%;
        }
    }

    &__specialization {
        background-image: url("../images/slime-specialization--mobile.png");
        background-size: 228px 169px;
        background-repeat: no-repeat;
        width: 230px;
        height: 170px;
        position: relative;

        @media (min-width: 768px) {
            margin-top: -50px;
        }

        @media (min-width: 1200px) {
            background-size: 258px 199px;
            width: 260px;
            height: 200px;
            margin-left: 100px;
        }
    }

    &__specialization-title {
        //   position: absolute;
        //   top: 35%;
        //   left: 49%;
        //   font-size: 25px;
        //   margin-right: -50%;
        //   transform: translate(-50%, 50%);
    }

    &__redirect {
        position: absolute;
        display: flex;
        flex-direction: column;
        bottom: 0;
              // top: 50%;
              left: 50%;
              bottom: 40px;
              transform: translate(-50%, 50%);

        // @media (max-width: 500px) {
        //     // top: 50%;
        //     left: 40%;
        // }

        // @media (min-width: 500px) {
        //     // top: 50%;
        //     left: 50%;
        //     bottom: 40px;
        //     transform: translate(-50%, 50%);

        // }

        @media (min-width: 768px) {
            // transform: translate(-40%, 1060%);
        }

        @media (min-width: 1024px) {
            // transform: translate(-40%, 730%);
        }

        @media (min-width: 1200px) {
            // transform: translate(-40%, 770%);
        }

        &--title {
            font-family: 'Indie Flower', cursive;
            font-size: 25px;
            margin-bottom: 2px;
        }

        &--arrow-wrapper {
              margin: 0 auto;
              width: 50px;
              height: 50px;

              @media (min-width: 500px) {
                  margin-bottom: -2px;
              }

              @media (min-width: 1024px) {
                  width: 40px;
                  height: 40px;
              }

              @media (min-width: 1200px) {
                  width: 42px;
                  height: 42px;
              }
        }

        &--arrow {
              margin-left: 12px;
        }
    }

    &__slime {
        background-size: 1200px 1200px;
        background-image: url("../images/slime--mobile.png");
        background-repeat: no-repeat;
        background-position: -560px 50px;
        background-color: #000057;
        height: 820px;
        width: 100%;
        margin: 0;
        // position: absolute;

        @media (min-width: 500px) {
            background-size: 1350px 1350px;
            background-position: -640px -50px;
            height: 900px;
        }

        @media (min-width: 768px) {
            background-size: 2000px 2000px;
            background-position: -1000px -400px;
            height: 1024px;
        }

        @media (min-width: 1024px) {
            background-size: 1388px 1558px;
            background-position: -650px -400px;
            height: 650px;
        }

        @media (min-width: 1200px) {

            background-position: -550px -400px;
            height: 700px;
        }
    }
}

.container-fluid {
    padding-left: 0 !important;
    padding-right: 0 !important;
}
