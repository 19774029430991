.intro {
    //background-color: pink;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;

    @media (min-width: 500px) {
        //height: 900px;
    }

    @media (min-width: 768px) {
        //height: 900px;

    }

    @media (min-width: 1024px) {

        flex-direction: row;
    }

    @media (min-width: 1200px) {

    }

    &__image-wrapper {
        width: 100%;
        // height: 420px;
        //background-color: red;

        // @media (min-width: 768px) {
        //     height: 650px;
        // }

        @media (min-width: 1024px) {
            position: relative;
            height: 650px;
        }

        @media (min-width: 1200px) {
            min-width: 700px;
        }
    }

    &__text-wrapper {
        display: flex;
        width: 100%;
        // background-image: url("../images/bottom_block--mobile.png");
        // background-size: 700px 640px;
        // background-position: -100px -15px;
        // background-repeat: no-repeat;
        line-height: 2;
        padding: 50px;
        justify-content: center;

        // @media (min-width: 500px) {
        //     background-size: auto;
        //     background-image: url("../images/bottom_block--desktop.png");
        //     background-position: -400px -40px;
        // }


        // @media (min-width: 768px) {
        //     background-size: auto;
        //     background-position: -280px -40px;
        // }

        // @media (min-width: 1024px) {
        //     justify-content: center;
        //     // background-size: 842px 1099px;
        //     background-position: 5px -20px;
        //     // background-size: cover;
        //     background-image: url("../images/right_block--desktop.png");
        //     position: relative;
        //     padding: 100px;
        // }

        // @media (min-width: 1200px) {
        //     background-position: -32px -50px;
        // }
    }

    &__content-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;

      @media (min-width: 768px) {
        //   width: 600px;
      }

      @media (min-width: 1024px) {
        //   width: 500px;
        //   position: absolute;
        //   top: 53%;
        //   left: 55%;
        //   transform: translate(-50%, -50%);
      }

      @media (min-width: 1200px) {
          max-width: 900px;
          padding: 0;
      }
    }

    &__text, &__title {
        color: #33338E;
        font-family: 'Lora', sans-serif;
    }

    &__text {
        letter-spacing: 2px;
        font-size: 15px;
        margin-top: -20px;

        @media (min-width: 768px) {

            font-size: 18px;
        }

    }

    &__title {
        font-weight: 700;
        font-size: 50px;
        letter-spacing: 3px;
        text-align: center;
    }

    &__pagenumber {
        &--wrapper {
            display: flex;
            justify-content: center;
            width: 100%;
            margin-top: 15px;
            height: 50px;

            @media (min-width: 768px) {
                position: absolute;
                top: 165px;
                transform: rotate(-90deg);
                left: -50px;
                //background-color: green;
                width: 200px;
            }

            @media (min-width: 1024px) {
                top: 290px;
            }

        }
    }

    &__frame {
        background-image: url("../images/picture--mobile.png");
        background-size: 250px 280px;
        background-repeat: no-repeat;
        width: 250px;
        height: 280px;
        //background-color: green;
        margin-left: auto;
        margin-right: auto;
        margin-top: 45px;

        @media (min-width: 768px) {
            background-size: 360px 413px;
            width: 365px;
            height: 413px;
            margin-top: 100px;
        }

        @media (min-width: 1024px) {
            background-size: 365px 413px;
            background-repeat: no-repeat;
            width: 365px;
            height: 413px;
            //margin-top: 125px;
            //margin-left: 80px;
            top: 50%;
            left: 52%;
            transform: translate(-50%, -50%);
            position: absolute;
            margin: 0;
        }

        @media (min-width: 1200px) {
            background-size: 395px 443px;
            width: 395px;
            height: 443px;
            top: 54%;
        }

    }

    &__wave {
        width: 100%; /* Set your desired width */
        height: 500px; /* Set your desired height */
        background-image: url("../images/wave--desktop.png"); /* Path to your image */
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
}

.pagenumber {
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-weight: 700;
    color: #362F7F;
    letter-spacing:5px;

    @media (min-width: 768px) {
        font-size: 13px;
    }

    &__wrapper {
        display: flex;
    }

    &__circle {
      height: 4px;
      width: 4px;
      background-color: #362F7F;
      border-radius: 50%;
      display: inline-block;

      @media (min-width: 768px) {
          height: 3px;
          width: 3px;
      }

      &--wrapper {
          margin: -3px 10px 0 10px;

          @media (min-width: 768px) {
              margin: -6px 10px 0 10px;
          }

      }

    }

    &__text {
        color: #362F7F;
        font-size: 15px;
        font-family: 'Poppins', sans-serif;
        letter-spacing: 5px;
        margin: 0;

        @media (min-width: 768px) {
            font-size: 13px;
        }
    }
}
