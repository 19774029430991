.role {
  &__text-wrapper {
    max-width: 350px;
    text-align: center;
    //background-color: crimson;

    @media (min-width: 768px) {
        max-width: 500px;
    }

    @media (min-width: 1024px) {
        width: 100%;
        max-width: none;
        text-align: left;

        margin-bottom: 60px;
        padding-left: 60px;
        padding-top: 60px;
    }



  }

  &__text {
    //background-color: pink;
    max-width: 720px;
  }

  &__wrapper {
    margin-top: -200px;
    background-color: grey;
    width: 100%;
    height: 400px;
  }
}
