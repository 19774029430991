.content-music-terra {
  width: 100%;
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 20px;
  //background-color: blue;
  margin-top: 20px;

  @media (min-width: 500px) {
      //top: 1480px;
    //   top: 1510px;
  }

  @media (min-width: 692px) {
      //top: 1500px;
  }


  @media (min-width: 768px) {
    //   height: 2240px;
      //top: 2000px;
      margin-top: 60px;
  }

  @media (min-width: 768px) {
    //   height: 1350px;
      //top: 1024px;
  }


  @media (min-width: 1024px) {
    //   height: 1350px;
    //   top: 1150px;
  }

  @media (min-width: 1200px) {
    //   height: 700px;
      //top: 700px;
  }

  &__pagenumber-wrapper {
      width: 100%;
      //background-color: crimson;
      display: flex;
      justify-content: center;
      margin-bottom: 25px;

      @media (min-width: 768px) {
          position: absolute;
          top: 60%;
          transform: rotate(-90deg);
          left: -46%;
      }
  }

  &__title-wrapper {
      width: 100%;
      //background-color: green;
      margin-bottom: 50px;
      display: flex;
      justify-content: center;
  }

  &__title {
      font-family: 'Lora', sans-serif;
      font-weight: 700;
      font-size: 30px;
      color: #33338E;
      letter-spacing: 3px;


      @media (min-width: 768px) {
        font-size: 45px;
    }
  }

  &__text-wrapper {
    display: flex;
    justify-content: center;
    //   display: block;
    //   width: 100%;
    //   //background-color: yellow;

    //   display: flex;
    //   justify-content: center;

    //   @media (min-width: 768px) {
    //       display: flex;
    //       justify-content: center;
    //   }

    //   @media (min-width: 1200px) {
    //       display: none;
    //   }
  }

  &__text {
      color: #362F7F;
      font-family: 'Lora', sans-serif;
      letter-spacing: 3px;
      font-size: 13px;
      line-height: 2;
      text-align: center;
      width: 350px;

      margin-top: 40px;
      @media (min-width: 768px) {
          width: 700px;
          margin-top: 70px;
          //background-color: yellow;
          font-size: 15px;
      }
  }

  &__image-wrapper {

    margin: 0 auto;
    // width: 100%;
    display: flex;
    justify-content: center;

    @media (min-width: 500px) {
      // width: 100%;
      //background-color: yellow;
    }

    @media (min-width: 768px) {

      padding-left: 60px;
      padding-right: 60px;
      // height: 600px;
        //background-color: yellow;
    }


    @media (min-width: 1024px) {
      height: 600px;
        //background-color: yellow;
    }


  }

  &__image {
    // object-fit: contain;
    // width: 100%;
    max-width:100%;
  max-height:100%;
  }
}
