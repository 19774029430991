.mockup-wrapper {
    margin-bottom: 20px;
    margin-top: 40px;

    @media (min-width: 1024px) {
        width: 100%;
        position: relative;
    }
}


.mockup {
    background-image: url("../images/iphone_mockup.png");
    background-size: 278px 287px;
    background-repeat: no-repeat;
    width: 100%;
    // height: 287px;
    margin: 0 auto;

    @media (min-width: 500px) {
        background-size: 400px 389px;
        // width: 400px;
        // height: 389px;
    }

    @media (min-width: 768px) {
        background-size: 498px 487px;
        width: 498px;
        // height: 487px;
    }

    @media (min-width: 1024px) {
        background-size: 498px 487px;
        // width: 498px;
        // height: 487px;
        width: 100%;
        height: auto;
    }

    @media (min-width: 1200px) {
        background-size: 608px 597px;
        // width: 608px;
        // height: 597px;
        width: 100%;
        height: auto;
    }

}
