html,
body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

// Font
@import url('https://fonts.googleapis.com/css?family=Lora:400,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Poppins:400,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Nanum+Pen+Script&display=swap');
@import url('https://fonts.googleapis.com/css?family=Indie+Flower&display=swap');

// Atoms
@import './Mockup.scss';

@import './Home.scss';
@import './Intro.scss';
@import './Project.scss';

@import './IntroDetail.scss';
@import './PhasesPlantacle.scss';
@import './Role.scss';
@import './WireframesPlantacle.scss';
@import './WireframesDesktop.scss';

//SoundcloudBlownAway
@import './SoundCloudBlownAway.scss';
@import './YoutubeBlownAway.scss';
@import './ContentMusicTerra.scss';


.phase-runner-google-play {
    object-fit: cover;
    width: 200px;
}

.phase-runner-google-play-wrapper {
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;

    @media (min-width: 1024px) {
        justify-content: flex-start;
        padding-left:100px;
    }

}
